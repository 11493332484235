
document.addEventListener("turbolinks:load", function() {
(function ($) {
	// USE STRICT
	"use strict";

	new WOW().init();

})(jQuery);
});
