// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@popperjs/core")
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)



require('bootstrap')

require("packs/slick")

require("./accordion/accordion")
//  require('./tweenlite')

require('./owl.carousel.min')
require("./slick-custom")
require("./wow-custom")
    
     
  

// require("./revolution/jquery.themepunch.revolution.min")
// require("./revolution/jquery.themepunch.tools.min")
// require("./revolution/local/revolution.extension.actions.min")
// require("./revolution/local/revolution.extension.carousel.min")
// require("./revolution/local/revolution.extension.kenburn.min")
// require("./revolution/local/revolution.extension.layeranimation.min")
// require("./revolution/local/revolution.extension.migration.min")
// require("./revolution/local/revolution.extension.navigation.min")
// require("./revolution/local/revolution.extension.parallax.min")
// require("./revolution/local/revolution.extension.slideanims.min")
// require("./revolution/local/revolution.extension.video.min")


   
   
  
require('./main')
// require("./revo-custom") 
require("./revo-custom")  

require('./vlad')


